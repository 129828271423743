import React, { useState, useEffect } from 'react';
import axios from 'axios';

function App() {
  const [ad, setAd] = useState(null);

  useEffect(() => {
    const fetchAd = async () => {
      // Simulate random user ID and event
      const userId = Math.floor(Math.random() * 10) + 1; // Random user ID between 1 and 10
      const response = await axios.get('https://api.adtech.ny5.os.tunninet.com/ad', { params: { user_id: userId } });
      console.log("Fetched ad data:", response.data);
      setAd(response.data);
    };

    fetchAd();
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <h1>A Webapp pulling ads dynamically from a simulated Kafka user event stream</h1>
        {ad ? (
          <div>
            <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
              <tbody>
                <tr>
                  <td style={{ textAlign: 'left', padding: '10px' }}>User ID:</td>
                  <td style={{ textAlign: 'left', padding: '10px' }}>{ad.user_id}</td>
                </tr>
                <tr>
                  <td style={{ textAlign: 'left', padding: '10px' }}>User Action:</td>
                  <td style={{ textAlign: 'left', padding: '10px' }}>{ad.event}</td>
                </tr>
              </tbody>
            </table>
            <h2>{ad.ad}</h2>
            <div style={{ maxWidth: '300px', maxHeight: '300px', overflow: 'hidden', margin: '0 auto' }}>
              <img src={ad.image} alt={ad.ad} style={{ width: '100%', height: 'auto', borderRadius: '10px' }} />
            </div>
          </div>
        ) : (
          <p>Loading ad...</p>
        )}
      </header>
    </div>
  );
}

export default App;

